@import 'src/styles/base/variables';

.tip {
  background: $color-lp-1;
  border-radius: 8px;
  padding: 13px;
  color: $color-text;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 9px;

  &__icon {
  }

  &__content {
    span,
    p {
      color: $color-text;
    }

    a {
      color: $color-primary;
      text-decoration: underline;
      text-decoration-color: $color-primary;
    }
  }

  &__close {
    margin-left: auto;
    cursor: pointer;
  }

  @include breakpoint-md {
    &__content {
      font-size: 14px;
    }
  }
}

.customer-report {
  margin-top: 65px;
  margin-bottom: 54px;
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    list-style-type: none;
    &-card {
      background-color: $color-lp-2;
      border-radius: 25px;
      position: relative;
      overflow: hidden;
      min-height: 360px;
    }
    &-card-text {
      padding: 36px;
      max-width: 80%;
      display: flex;
      flex-direction: column;
      height: 100%;
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: $color-text;
        margin-top: 35px;
      }
    }
    &-card-text-btn {
      flex: 1;
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
    }
    &-card-image {
      position: absolute;
      right: -50px;
      bottom: -20px;
      img {
        max-width: 233px;
        min-width: 233px;
        min-height: 233px;
        max-height: 233px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    @include breakpoint-lg {
      &-card-image {
        img {
          max-width: 180px;
          min-width: 180px;
          min-height: 180px;
          max-height: 180px;
        }
      }
    }
    @include breakpoint-md {
      grid-template-columns: 1fr;
      row-gap: 15px;
      &-card-image {
        img {
          max-width: 180px;
        }
      }
    }
    @include breakpoint-sm {
      margin-top: 60px;
      margin-bottom: 46px;
      &-card {
        min-height: 253px;
      }
      &-card-image {
        bottom: -13px;
        right: -27px;
        img {
          max-width: 127px;
          min-width: 127px;
          min-height: 127px;
          max-height: 127px;
        }
      }
      &-card-text {
        padding: 20px;
        p {
          font-size: 12px;
          line-height: 20px;
          color: $color-text;
          margin-top: 21px;
        }
      }
    }
  }
  @include breakpoint-sm {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.community-news {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $color-primary;
    border-radius: 25px;
    padding: 53px 37px;
    margin-bottom: 57px;
    p {
      max-width: 90%;
      line-height: 20px;
      font-size: 15px;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      a {
        color: $color-primary;
        text-decoration: underline;
        text-decoration-color: $color-primary;
        &:hover {
          color: $color-primary-dark;
          text-decoration-color: $color-primary-dark;
        }
      }
    }
    h2 {
      margin-top: 32px;
    }
    &-images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      max-width: 80%;
      &-item {
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          min-height: 250px;
        }
      }
    }
    &-img {
      max-width: 160px;
    }
  }
  @include breakpoint-lg {
    &__content {
      &-images {
        grid-template-columns: 1fr;
      }
    }
  }
  @include breakpoint-md {
    &__content {
      padding: 23px;
      p {
        margin-top: 15px;
        margin-bottom: 25px;
      }
      &-images {
        max-width: 100%;
      }
    }
  }
  @include breakpoint-sm {
    &__content {
      margin-bottom: 30px;
      p {
        max-width: 100%;
        font-size: 12px;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
      }
      &-images {
        margin-top: 10px;
        &-item {
          img {
            min-height: 150px;
          }
        }
      }
    }
  }
}

.our-community-main {
  .customer-report,
  .customer-report__container {
    margin-top: 0;
  }
}

.latest-articles {
  background-color: $color-lp-1;
  padding-top: 49px;
  padding-bottom: 70px;
  &__item {
    box-shadow: 0px 3px 10px 0px #0000001a;
    border-radius: 25px;
    background-color: white;
  }
  &__link-mobile {
    display: none;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 58px;
  }
  &__content-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 26px;
  }
  &__item-img {
    position: relative;
    img {
      width: 100%;
      max-height: 268px;
      min-height: 268px;
      object-fit: cover;
      border-radius: 25px 25px 0 0;
    }
  }
  &__item-tag {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    p {
      background-color: white;
      padding: 6px 11px;
      border-radius: 18px;
      box-shadow: 0px 3px 10px 0px #0000001a;
      font-size: 12px;
      color: $color-text;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
  &__item-text {
    border-radius: 0 0 25px 25px;
    padding: 24px 23px 29px 18px;
    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 26px;
    }
    .decor-divider {
      display: none;
    }
  }
  @include breakpoint-lg {
    &__item-img {
      img {
        max-height: 168px;
        min-height: 168px;
      }
    }
  }
  @include breakpoint-md {
    &__content-inner {
      grid-template-columns: 1fr 1fr;
    }
  }
  @include breakpoint-sm {
    padding-top: 34px;
    padding-bottom: 34px;
    &__content-inner {
      grid-template-columns: 1fr;
      gap: 13px;
    }
    &__item-img {
      img {
        max-height: 105px;
        min-height: 105px;
      }
    }
    &__item-text {
      padding: 15px 20px 20px 15px;
      h3 {
        font-size: 16px;
      }
      .decor-divider {
        display: block;
        margin-top: 6px;
      }
    }
    &__head {
      margin-bottom: 28px;
      a {
        display: none;
      }
      h2 {
        max-width: 80%;
      }
    }
    &__link-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 28px;
    }
    &__item-tag {
      top: 18px;
      right: 13px;
    }
  }
  ul {
    li {
      list-style-type: none;
    }
  }
}

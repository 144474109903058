@import 'src/styles/base/variables';

.review-banner {
  height: 180px;
  border-radius: 25px;
  border: 1px solid $color-primary;
  place-items: center;
  .iframe-small {
    display: none;
  }

  @include breakpoint-md {
    height: unset;
    grid-gap: 30px;
    padding: 40px;
  }

  @include breakpoint-sm {
    height: unset;
    grid-gap: 30px;
    padding: 40px;
    .iframe-small {
      display: block;
    }
    .iframe-large {
      display: none;
    }
  }
}

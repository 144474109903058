@import 'src/styles/base/variables';

.slider-home {
  min-height: 585px;
  position: relative;
  background-color: #f8f5f9;
  max-width: 1440px;
  margin: 0 auto;
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 100px;
    padding: 0 20px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  &__bg-img {
    position: relative;
    max-width: 100%;
    height: 100%;
    max-height: 585px;
    object-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 6.85%, rgba(0, 0, 16, 0) 100%);
      z-index: 1;
      height: 100%;
      width: 100%;
    }
  }

  &__img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      max-width: 100%;
    }
  }

  &__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 70px;
    padding-top: 20px;
    max-width: 80%;
    z-index: 10;
    h1 {
      color: white;
    }
    button {
      border: 1.5px solid white;
      padding: 13px 30px !important;
    }
    &__divider {
      width: 181px;
      margin: 16px 0;
      background: linear-gradient(80deg, #632573 0% 27%, #ffffff 27.01% 100%);
    }
    &__sub-text {
      margin-bottom: 49px;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-top: 7px;
      p,
      span {
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &__controls {
    position: absolute;
    bottom: 46px;
    right: 61px;
    &__btn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $color-primary;
      background-color: white;
      cursor: pointer;
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      &--active {
        background-color: $color-primary;
      }
    }
  }

  @include breakpoint-lg {
    min-height: 480px;
  }
  @include breakpoint-md {
    min-height: 380px;
    &__grid {
      grid-gap: 10px;
    }
    &__contents {
      &__sub-text {
        margin-bottom: 20px;
        font-size: 14px;
        p,
        span {
          font-size: 14px;
        }
      }
      &__divider {
        width: 141px;
        margin: 12px 0;
      }
      button {
        padding: 7px 16px !important;
      }
    }
    &__controls {
      &__btn {
        width: 10px;
        height: 10px;
      }
    }
  }
  @include breakpoint-sm {
    min-height: 173px;
    &__grid {
      padding: 0 20px;
      grid-template-columns: 1fr;
      grid-gap: 0px;
      margin-left: 0;
      &-mobile {
        background-color: $color-lp-2;
        margin: 0px 20px;
        padding: 11px 22px 22px 22px;
        border-radius: 0px 0px 8px 8px;
        min-height: 210px;
        h1 {
          font-size: 20px !important;
        }
      }
    }
    &__contents {
      order: 0;
      padding-top: 10px;
      padding-bottom: 0px;
      &__sub-text {
        margin-bottom: 10px;
        margin-top: 5px;
        font-size: 12px;
        p,
        span {
          font-size: 12px;
        }
      }
      &__divider {
        width: 59px;
        margin: 8px 0;
      }
      h1 {
        font-size: 18px !important;
      }
      .btn {
        font-size: 12px !important;
        margin-top: 12px;
      }
      display: none;
      &-mobile {
        display: block;
      }
    }
    &__img-container {
      align-items: flex-start;
      order: 1;
      max-width: 120px;
      margin-left: auto;
      margin-top: -50px;
    }

    &__controls {
      bottom: 15px;
      right: 20px;
      left: auto;
    }
    &__controls {
      &__btn {
        width: 7px;
        height: 7px;
      }
    }
    &__bg-img {
      max-height: 175px;
    }
  }
}

.dots {
  display: flex;
  grid-gap: 15px;
  gap: 15px;

  @include breakpoint-sm {
    grid-gap: 5px;
    gap: 5px;
  }
}

#slider-home-mobile {
  .keen-slider__slide {
    background-color: white;
  }
  .slider-home__contents__sub-text,
  p {
    color: #632573;
    line-height: 16px;
    display: block;
  }
  .slider-home__contents__divider {
    background: linear-gradient(80deg, #632573 0% 27%, #2a042d 27.01% 100%) !important;
  }
  .btn {
    font-size: 12px !important;
  }
  @media (min-width: 600px) {
    display: none;
  }
}

// WHY CBS SECTION
.cbs {
  margin-top: 67px;
  margin-bottom: 73px;
  &__card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 63px;
  }
  &__card-image-wrap {
    position: relative;
    flex-basis: 42%;
    img {
      border-radius: 25px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    align-self: center;
    li {
      list-style-type: none;
      display: flex;
      flex-direction: column;
    }
  }
  &__card-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    background-color: $color-lp-2;
    padding: 30px 40px;
    border-radius: 25px 0px 25px 25px;
    cursor: pointer;
    svg {
      max-width: 42px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover {
      background-color: $color-primary;
      p,
      h3 {
        color: white;
      }
      svg {
        path {
          fill: white;
        }
      }
      .support-icon {
        path {
          stroke: #9c84ad;
        }
      }
    }
  }
  &__card-img-wrap {
    max-height: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__card-item-text {
    margin-top: 8px;
    font-size: 26px;
    font-weight: 500;
    max-width: 245px;
    text-align: left;
    line-height: 31px;
    text-decoration: underline;
    color: $color-primary;
  }
  &__image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    h2 {
      font-size: 48px;
      color: white;
      font-weight: 600;
      padding-left: 25px;
      padding-bottom: 18px;
    }
  }
  @include breakpoint-lg {
    &__card {
      gap: 30px;
    }

    &__card-item-text {
      font-size: 22px;
    }
    &__image-overlay {
      h2 {
        font-size: 32px;
      }
    }
    &__card-item {
      padding: 20px;
      p {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  @include breakpoint-md {
    &__card-item {
      img {
        max-width: 25px;
      }
    }
    &__card-content {
      gap: 10px;
    }
    &__image-overlay {
      padding-bottom: 20px;
      h2 {
        font-size: 26px;
      }
    }
    &__card-item-text {
      font-size: 16px;
      line-height: 20px;
    }
    &__card-img-wrap {
      max-height: 50px;
    }
  }
  @include breakpoint-sm {
    &__card {
      grid-template-columns: 1fr;
    }
    &__card-image-wrap {
      flex-basis: 100%;
    }
    &__card-content {
      flex-basis: 100%;
    }
    &__card-item {
      padding: 10px 20px;
    }
    &__card-item-text {
      font-size: 14px !important;
    }
    &__card-img-wrap {
      height: 35px;
      margin-bottom: 5px;
    }
    &__image-overlay {
      padding-bottom: 0px;
      h2 {
        font-size: 24px;
        padding-right: 30px;
      }
    }
    margin-top: 38px;
    margin-bottom: 47px;
  }
}

$color-primary: #632573;
$color-primary-dark: #2a042d;
$color-lp-1: #f8f5f9;
$color-verdigris: #43b8b0;
$color-verdigris-dark: #4d6978;
$color-lp-2: #f4f0f6;
$color-text: #353535;
$color-pink: #b63a6f;
$gradient: linear-gradient(90deg, $color-primary-dark 0.01%, $color-primary 99.99%);
$gradient-reverse: linear-gradient(90deg, $color-primary 0.01%, $color-primary-dark 99.99%);

@mixin breakpoint-xs {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin breakpoint-sm {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin breakpoint-md {
  @media (max-width: 950px) {
    @content;
  }
}
@mixin breakpoint-lg {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin breakpoint-xl {
  @media (max-width: 1400px) {
    @content;
  }
}

@import 'src/styles/base/variables';

.opt {
  margin-top: 10px;

  h2 {
    text-align: center;
  }
  ul {
    li {
      list-style-type: none;
    }
  }

  &__grid {
    margin-top: 33px;
    display: grid;
    grid-gap: 26px;

    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--1-img {
      .opt__grid__item {
        display: flex;
      }
    }
    &__item {
      position: relative;
      height: 244px;
      position: relative;
      border-radius: 26px;
      display: grid;
      overflow: hidden;
      cursor: pointer;
      user-select: none;

      &__label {
        text-align: center;
        color: white;
        position: absolute;
        bottom: 35px;
        width: 100%;
        //   font-size: 30px;
        font-weight: 500;
        font-family: 'Diodrum Cyrillic';
        z-index: 2;
        padding: 0 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s;
        position: relative;
        z-index: -1;
      }

      &:after {
        content: '';
        position: absolute;
        height: 50%;
        width: 100%;
        border-radius: 0px 0px 26px 26px;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 6.85%, rgba(0, 0, 26, 0) 100%);
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      &__img--small {
        object-position: center;
      }
    }
  }

  @include breakpoint-md {
    &__grid {
      margin-top: 20px;
      grid-gap: 10px;
      &__item {
        &__label {
          bottom: 18px;
          font-size: 26px;
        }
      }
    }
  }
  @include breakpoint-sm {
    h2 {
      text-align: left;
    }
    &__grid {
      grid-template-columns: 1fr;

      &__item {
        height: 156px;
      }
    }
  }
}
